import { ComponentProps } from 'react';
import { Th } from '@itandi/itandi-bb-ui';
import { DealConstructionSchedule } from '~/models/DealConstructionSchedule';
import { DealUploadFile, DealUploadFiles } from '~/models/DealUploadFile';
import { VendorCompany } from '~/models/VendorCompany';
import { Staff } from '~/models/Staff';
import { InspectionCandidateDates } from '~/models/InspectionCandidateDate';
import { CompanySetting } from '~/models/CompanySetting';
import { DealCancel } from '~/models/DealCancel';
import {
  DealVendorCompany,
  getOriginDealVendor
} from '~/models/DealVendorCompany';
import { DealStatusReason } from './DealStatusReason';
import { VendorUser } from '~/models/VendorUser';
import { DealEstimationContext } from '~/models/DealEstimate';
import { DealBillingContext } from '~/models/DealBilling';
import { Status, StatusId } from '~/models/Status';
import { Subtype } from '~/models/Subtype';
import { CompanyStoreSetting } from './CompanyStoreSetting';
import { DealRepair } from './DealRepair';
import { PartnerDeal } from '~/models/sk/PartnerDeal';
import { DealHistory } from '~/models/DealHistory';

export type DealType = 'restoration' | 'repair' | 'other';
export type Deal = {
  id: number;
  name: string;
  address: string;
  dealType: DealType;
  dealSubtype: Subtype | null;
  estimateAddressName: string;
  enableAutoLock: boolean;
  enableElevator: boolean;
  conductFieldSurvey: boolean;
  freeParking: string;
  note: string;
  preferredDate: string;
  roomNumber: string;
  placeKeyStoring: string;
  tenantName: string | null;
  tenantNameKana: string | null;
  tenantTel: string | null;
  tenantEmail: string | null;
  terminationDate: string;
  inspectionDate: string | null;
  floorMap: string;
  leaseContractFile1: string | null;
  leaseContractFile1OriginalFilename: string | null;
  leaseContractFile2: string | null;
  leaseContractFile2OriginalFilename: string | null;
  leaseContractFile3: string | null;
  leaseContractFile3OriginalFilename: string | null;
  status: number;
  createdAt: string;
  updatedAt: string;
  dealInspectionCandidateDates: InspectionCandidateDates;
  dealConstructionSchedules: DealConstructionSchedule[];
  dealRepair: DealRepair | null;
  dealCancel: DealCancel | null;
  dealMemorandumFiles: DealUploadFiles;
  dealReceiptFiles: DealUploadFiles;
  dealStatusReasons: DealStatusReason[];
  dealOtherFiles: DealUploadFiles;
  dealCompanyPrivateFiles?: ReadonlyArray<DealUploadFile>;
  dealVendorCompanies: DealVendorCompany[];
  vendorCompany: VendorCompany;
  vendorCompanyUser: VendorUser;
  companySetting: CompanySetting;
  staff: Staff;
  propertyDealId: number;
  relatedDeals: RelatedDeal[];
  leasingId: number | null;
  propertyType: 'room' | 'building' | null;
  propertyId: number | null;
};

export type DealWithCompanyAndVendor = {
  id: number;
  name: string;
  address: string;
  estimateAddressName: string;
  enableAutoLock: boolean;
  enableElevator: boolean;
  freeParking: string;
  note: string;
  preferredDate: string;
  roomNumber: string;
  placeKeyStoring: string;
  tenantName: string | null;
  tenantNameKana: string | null;
  tenantTel: string | null;
  tenantEmail: string | null;
  terminationDate: string;
  inspectionDate: string;
  floorMap: string;
  leaseContractFile1: string;
  leaseContractFile2: string;
  leaseContractFile3: string;
  status: number;
  createdAt: string;
  updatedAt: string;
  originVendorCompany: VendorCompany | null;
  originVendorCompanyUser: VendorUser | null;
  companySetting: CompanySetting;
  staff: Staff | null;
};

/**
 * 案件一覧のDealModel(管理会社)
 */
export type DealIndexClient = {
  id: number;
  authId: number;
  propertyDealId: number;
  name: string;
  roomNumber: string | null;
  address: string;
  dealType: DealType;
  dealSubtype: Subtype | null;
  status: StatusId;
  tenantName: string | null;
  tenantNameKana: string | null;
  terminationDate: string | null;
  inspectionDate: string | null;
  constructionStartDate: string | null;
  constructionEndDate: string | null;
  leaseContractFile1: string;
  staff: Staff;
  user: VendorUser;
  companySetting: CompanySetting;
  companyStoreSetting: CompanyStoreSetting;
  dealVendorCompanies: DealVendorCompany[];
  completedDate: string;
  uncompletedTodoCount: number;
  unreadMessageCount: number;
  relatedDealCount: number;
  preferredDate: string | null;
  createdAt: string;
  updatedAt: string;
};

/**
 * 案件一覧のDealModel(施工会社)
 */
export type DealIndexVendor = {
  id: number;
  name: string;
  roomNumber: string | null;
  address: string;
  dealType: DealType;
  dealSubtype: Subtype | null;
  status: StatusId;
  tenantName: string | null;
  tenantNameKana: string | null;
  terminationDate: string | null;
  inspectionDate: string | null;
  constructionStartDate: string | null;
  constructionEndDate: string | null;
  preferredDate: string | null;
  leaseContractFile1: string | null;
  staff: Staff;
  user: VendorUser;
  originVendorCompany: VendorCompany;
  originVendorUserName: string | null;
  subVendorCompany: VendorCompany | null;
  subVendorUserName: string | null;
  companySetting: CompanySetting;
  maintenanceCompanyName: string;
  maintenanceCompanyStoreName: string;
  completedDate: string;
  dealHistories: DealHistory[];
  unreadMessageCount: number;
  createdAt: string;
  updatedAt: string;
};

export type DealWithPrices = {
  id: number;
  dealType: DealType;
  dealSubtype: Subtype | null;
  status: Status;
  originDealVendorCompany: DealVendorCompany | null;
  dealEstimationContext: DealEstimationContext;
  dealOwnerBillingContext: DealBillingContext;
  dealRenteeBillingContext: DealBillingContext;
  skPartnerDeal: PartnerDeal | null;
};

/**
 * 関連する案件
 */
export type RelatedDeal = {
  id: number;
  name: string;
  roomNumber: string;
  createdAt: string;
  updatedAt: string;
};

export type DealSearchCondition = {
  name?: string;
  address?: string;
  tenantName?: string;
  companyMasterId?: number;
  authId?: number;
  companyVendorId?: number;
  statusId?: number[];
  dealType?: DealType;
  dealSubtypeId?: number | null;
  dealTypes?: DealType[];
  dealSubtypeIds?: number[];
  staffId?: number;
  userId?: number;
  partnerVendorId?: number;
  constructionMinStartDate?: string;
  constructionMaxStartDate?: string;
  constructionMinEndDate?: string;
  constructionMaxEndDate?: string;
  inspectionMinDate?: string;
  inspectionMaxDate?: string;
  registrationMinDate?: string;
  registrationMaxDate?: string;
  page?: number;
  sortKey?: DealSortKey;
  sortDirection?: ComponentProps<typeof Th>['sort'];
};

export type DealSortKey =
  | 'registration_date'
  | 'status_id'
  | 'termination_date'
  | 'inspection_date'
  | 'construction_start_date'
  | 'construction_end_date'
  | 'preferred_date';

export const isNumberCondition = (key: string): boolean =>
  [
    'companyMasterId',
    'authId',
    'companyVendorId',
    'staffId',
    'userId',
    'partnerVendorId',
    'page'
  ].includes(key);

const isDetailCondition = (key: string): boolean =>
  [
    'dealTypes',
    'statusId',
    'name',
    'address',
    'tenantName',
    'companyMasterId',
    'companyVendorId',
    'partnerVendorId',
    'authId',
    'staffId',
    'userId',
    'constructionMinStartDate',
    'constructionMaxStartDate',
    'constructionMinEndDate',
    'constructionMaxEndDate',
    'inspectionMinDate',
    'inspectionMaxDate',
    'registrationMinDate',
    'registrationMaxDate'
  ].includes(key);

export const searchDetailBadge = (
  dealSearchCondition: DealSearchCondition
): number => {
  let ret = 0;
  Object.entries(dealSearchCondition).forEach(([key, value]) => {
    if (isDetailCondition(key)) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          ret += 1;
        }
      } else if (value) {
        ret += 1;
      }
    }
  });
  return ret;
};

export const isWithVendorDeal = (deal: Deal): boolean => {
  const originDealVendor = getOriginDealVendor(deal);
  if (originDealVendor === null) {
    // 施工会社未選択は一旦施工会社も呼ぶ前提として扱う
    return true;
  }
  return originDealVendor.vendorCompany !== null;
};

export const matchDealType = (
  deal: Deal | DealIndexClient | DealIndexVendor,
  dealType: 'restoration' | 'repair' | 'other'
): boolean => deal.dealType === dealType;

export const DEAL_TYPE_LABEL = {
  restoration: '原状回復',
  repair: '修繕',
  other: 'その他'
} as const;
// typescript4.9, next13以降からしかsatisfiesが使えない
// } as const satisfies {
//   [key in DealType]: string;
// }
