import React from 'react';
import { toast } from 'react-toastify';
import {
  InputCheckBox,
  ComboBox,
  ComboBoxOptionType,
  Tooltip
} from '@itandi/itandi-bb-ui';
import Styles from './CompanyVendorSelector.module.scss';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { useCompanyVendors } from '~/hooks/useCompanyVendors';
import {
  CompanyVendor,
  getCompanyVendorNameWithStatus
} from '~/models/CompanyVendor';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { create } from '~/utils/api/deals/DealVendorCompany';
import { isSuccess } from '~/utils/api/api_handler';
import useLoader from '~/hooks/useLoader';
import { useDeals } from '~/hooks/useDeals';
import useItandiAccount from '~/hooks/useItandiAccount';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { VENDOR_COMPANY_ITANDI_ID } from '~/models/VendorCompany';
import { NOW_ACCEPTING, statusMovingValidation } from '~/models/Status';
import { AccordionHtml } from '../accordion_html/AccordionHtml';

export const CompanyVendorSelector: React.FC = () => {
  const dealId = useIdQueryHandler();
  const { Loader, showLoader, hideLoader } = useLoader();
  const { isOpen, handleOpen, handleClose } = useOpenHandler();

  const { data: deal, mutate: mutateDeal } = useDeals().show(dealId);
  const { data: companyVendors, isLoading } = useCompanyVendors().index();
  const { data: account } = useItandiAccount().currentAccount();

  const savedCompanyVendor =
    deal?.dealVendorCompanies[0]?.companyVendor ?? null;
  const savedDealVendorCompany = deal?.dealVendorCompanies[0] ?? null;
  const [companyVendorId, setCompanyVendorId] = React.useState<number | null>(
    savedCompanyVendor ? savedCompanyVendor.id : null
  );

  const [showTenantChecked, setShowTenantChecked] =
    React.useState<boolean>(false);
  const [skipEstimation, setSkipEstimation] = React.useState<boolean>(false);

  const selectedCompanyVendor = React.useMemo(() => {
    if (companyVendors === null) {
      return null;
    }
    return companyVendors.find((v) => v.id === companyVendorId) ?? null;
  }, [companyVendorId, companyVendors]);

  // companyVendorが選択されたら、デフォルト値に従ってデフォルト値を決める
  React.useEffect(() => {
    if (selectedCompanyVendor === null) {
      return;
    }
    setShowTenantChecked(selectedCompanyVendor.showTenantDefault);
    if (
      selectedCompanyVendor.id === savedDealVendorCompany?.companyVendor?.id
    ) {
      setSkipEstimation(savedDealVendorCompany.skipEstimation);
      setShowTenantChecked(savedDealVendorCompany.showTenant);
    } else if (selectedCompanyVendor.vendorEstimationRequired) {
      setSkipEstimation(false);
    } else {
      setSkipEstimation(selectedCompanyVendor.skipEstimationDefault);
    }
  }, [savedDealVendorCompany, selectedCompanyVendor]);

  const onChangeCompanyVendor = React.useCallback(
    (option: ComboBoxOptionType | null): void => {
      if (companyVendors === null) return;
      const value = option?.value ?? '';
      if (value === '') {
        setCompanyVendorId(null);
        return;
      }
      const selectCompanyVendor =
        companyVendors.find((c: CompanyVendor) => c.id === Number(value)) ??
        null;
      setCompanyVendorId(selectCompanyVendor?.id ?? null);
    },
    [companyVendors]
  );

  const forwardStatusDisabled = React.useMemo(() => {
    if (account === null || companyVendorId === null) return true;
    return !statusMovingValidation[NOW_ACCEPTING](account);
  }, [account, companyVendorId]);

  if (deal === null || companyVendors === null || isLoading) {
    return null;
  }
  const handleAcceptForward = async (): Promise<void> => {
    handleClose();
    if (companyVendorId === null) return;
    showLoader();
    const result = await create(
      deal.id,
      companyVendorId,
      showTenantChecked,
      skipEstimation
    );
    if (isSuccess(result)) {
      mutateDeal && (await mutateDeal());
      hideLoader();
      toast.success('更新しました');
      return;
    }
    hideLoader();
    toast.error('エラーが発生しました');
  };
  return (
    <div>
      <Loader />
      <div className={Styles.NavigationBlock}>
        <div className={Styles.NavigationContent}>
          {savedCompanyVendor !== null && (
            <p className={Styles.SavedCompanyVendor}>
              現在依頼中の施工会社:
              {getCompanyVendorNameWithStatus(savedCompanyVendor)}
            </p>
          )}
          <ComboBox
            defaultValue={
              savedCompanyVendor !== null
                ? {
                    value: String(savedCompanyVendor.id),
                    label: getCompanyVendorNameWithStatus(savedCompanyVendor)
                  }
                : null
            }
            isMulti={false}
            maxMenuHeight={200}
            onChange={onChangeCompanyVendor}
            options={
              companyVendors?.map((c: CompanyVendor) => ({
                value: String(c.id),
                label: `${getCompanyVendorNameWithStatus(c)}`
              })) ?? []
            }
            placeholder="選択してください"
          />
        </div>
      </div>
      {selectedCompanyVendor !== null &&
        selectedCompanyVendor.cautionaryNoteHtml !== '' && (
          <div className={Styles.NavigationBlock}>
            <div className={Styles.NavigationContent}>
              <span className={Styles.NavigationTitle}>注意事項</span>
              <AccordionHtml
                html={selectedCompanyVendor.cautionaryNoteHtml}
                maxHeightForClosed={150}
              />
            </div>
          </div>
        )}
      {companyVendorId !== null && (
        <>
          {selectedCompanyVendor?.vendorCompany?.id !==
            VENDOR_COMPANY_ITANDI_ID && (
            <div className={Styles.NavigationBlock}>
              <InputCheckBox
                checked={showTenantChecked}
                labelText="施工会社に入居者の情報を表示する"
                onChange={(): void => setShowTenantChecked(!showTenantChecked)}
              />
            </div>
          )}
          {(!selectedCompanyVendor?.vendorEstimationRequired ||
            selectedCompanyVendor?.skipEstimationDefault) && (
            <div className={Styles.NavigationBlock}>
              <InputCheckBox
                checked={skipEstimation}
                disabled={selectedCompanyVendor?.vendorEstimationRequired}
                labelText="施工会社の見積入力をスキップする"
                onChange={(): void => setSkipEstimation(!skipEstimation)}
              />
              {selectedCompanyVendor?.vendorEstimationRequired && (
                <Tooltip>
                  この施工会社はシステム上見積入力が必須に設定されています
                </Tooltip>
              )}
            </div>
          )}
        </>
      )}
      <div className={Styles.StatusHandleButtonRow}>
        <div className={Styles.RightButtons}>
          <button
            className={Styles.ForwardButton}
            disabled={forwardStatusDisabled}
            onClick={handleOpen}
            type="button"
          >
            施工会社を{deal.dealVendorCompanies.length === 0 ? '設定' : '変更'}
            する
          </button>
        </div>
        <ConfirmModal
          actionText="確定"
          confirmType="save"
          handleAccept={handleAcceptForward}
          handleCancel={handleClose}
          isOpen={isOpen}
        >
          施工会社を
          {deal.dealVendorCompanies.length === 0 ? '設定' : '変更'}
          してよろしいですか ？
        </ConfirmModal>
      </div>
    </div>
  );
};
