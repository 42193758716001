import React from 'react';
import { ComboBoxOptionType, ComboBox } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { DealSearchCondition } from '~/models/Deal';
import {
  convertToComboBoxOption,
  DealCompanySetting
} from '~/models/CompanySetting';

type Props = {
  handleChangeMaintenanceCompanyInput: (inputText: string) => void;
  selectedCompanyMasterId: number | undefined;
  maintenanceCompanies: DealCompanySetting[] | null;
  onChangeCompanyMasterId: (
    companyMasterId: number | undefined
  ) => Promise<void>;
};

export const MaintenanceCompanyCombobox: React.FC<Props> = ({
  handleChangeMaintenanceCompanyInput,
  selectedCompanyMasterId,
  maintenanceCompanies,
  onChangeCompanyMasterId
}: Props) => {
  const { setValue } = useFormContext<DealSearchCondition>();
  const selectedMaintenanceCompany =
    maintenanceCompanies?.find(
      ({ companyMasterId }) => companyMasterId === selectedCompanyMasterId
    ) || null;
  const onChangeOption = React.useCallback(
    async (option: ComboBoxOptionType | null): Promise<void> => {
      const companyMasterId = option ? Number(option.value) : undefined;
      setValue('companyMasterId', companyMasterId);
      await onChangeCompanyMasterId(companyMasterId);
    },
    [onChangeCompanyMasterId, setValue]
  );
  return (
    <ComboBox
      defaultValue={
        selectedMaintenanceCompany
          ? convertToComboBoxOption(selectedMaintenanceCompany)
          : null
      }
      isMulti={false}
      onChange={onChangeOption}
      onInputChange={handleChangeMaintenanceCompanyInput}
      options={
        maintenanceCompanies?.map((cs: DealCompanySetting) =>
          convertToComboBoxOption(cs)
        ) || []
      }
      placeholder="管理会社"
    />
  );
};
