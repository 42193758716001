import React from 'react';
import { RiMoreFill } from 'react-icons/ri';
import {
  Input,
  ItandiChip,
  InputSelect,
  InputText,
  InputCheckBox,
  ComboBox,
  ComboBoxOptionType,
  Tooltip
} from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './Block.module.scss';
import { DealParam } from '~/models/DealForm';
import { useStaffs } from '~/hooks/useStaffs';
import { Staff } from '~/models/Staff';
import { useCompanyVendors } from '~/hooks/useCompanyVendors';
import {
  CompanyVendor,
  getCompanyVendorNameWithStatus
} from '~/models/CompanyVendor';
import { VENDOR_COMPANY_ITANDI_ID } from '~/models/VendorCompany';
import { AccordionHtml } from '~/components/common/accordion_html/AccordionHtml';
import useItandiAccount from '~/hooks/useItandiAccount';

export const OtherBlock: React.FC = () => {
  const { register, formState, setValue, watch } = useFormContext<DealParam>();
  const { errors } = formState;
  const { data: staffs } = useStaffs().index();
  const { data: companyVendors } = useCompanyVendors().index();
  const { data: account } = useItandiAccount().currentAccount();
  React.useEffect(() => {
    const companyVendor = companyVendors?.[0] ?? null;
    if (companyVendor === null) return;
    setValue('staff', account?.staff ?? null);
    setValue('companyVendor', companyVendor);
    setValue('showTenant', companyVendor.showTenantDefault);
    setValue('skipEstimation', companyVendor.skipEstimationDefault);
    if (account?.company?.autoFillEstimateAddressType === 'company_name') {
      setValue('estimateAddressName', account?.company?.name);
    }
  }, [account, companyVendors, setValue]);
  const onChangeStaff = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const { value } = e.target;
      if (value === '') {
        setValue('staff', null);
        return;
      }
      if (staffs !== null) {
        const selectStaff =
          staffs.find((s: Staff) => s.id === Number(value)) ?? null;
        setValue('staff', selectStaff);
      }
    },
    [staffs, setValue]
  );
  const onChangeCompanyVendor = React.useCallback(
    (option: ComboBoxOptionType | null): void => {
      if (companyVendors === null) return;
      const value = option?.value ?? '';
      if (value === '') {
        setValue('companyVendor', null);
        setValue('showTenant', true);
        setValue('skipEstimation', false);
        return;
      }
      const selectCompanyVendor =
        companyVendors.find((c: CompanyVendor) => c.id === Number(value)) ??
        null;
      setValue('companyVendor', selectCompanyVendor);
      setValue('showTenant', selectCompanyVendor?.showTenantDefault ?? true);
      setValue(
        'skipEstimation',
        selectCompanyVendor?.skipEstimationDefault ?? false
      );
    },
    [companyVendors, setValue]
  );
  const onChangeCheckBox = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      attributeName: 'showTenant' | 'skipEstimation'
    ): void => {
      const { checked } = e.target;
      setValue(attributeName, checked);
    },
    [setValue]
  );
  const companyVendor = watch('companyVendor');
  const staff = watch('staff');
  return (
    <div className={Styles.Block}>
      <div className={Styles.BlockCaptionWrapper}>
        <div className={Styles.BlockCaption}>
          <span className={Styles.BlockIconWrapper}>
            <RiMoreFill className={Styles.BlockIcon} />
          </span>
          <span className={Styles.BlockCaptionLabel}>その他案件情報</span>
        </div>
      </div>
      <div className={Styles.BlockForms}>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>案件担当者</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <InputSelect
                onChange={onChangeStaff}
                value={staff !== null ? String(staff.id) : ''}
              >
                <option value="">選択してください</option>
                {staffs !== null &&
                  staffs.map((s: Staff) => (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  ))}
              </InputSelect>
            </div>
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>依頼先の施工会社</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <ComboBox
                isMulti={false}
                maxMenuHeight={190}
                onChange={onChangeCompanyVendor}
                options={
                  companyVendors?.map((c: CompanyVendor) => ({
                    value: String(c.id),
                    label: `${getCompanyVendorNameWithStatus(c)}`
                  })) ?? []
                }
                placeholder="選択してください"
                value={
                  companyVendor && {
                    label: getCompanyVendorNameWithStatus(companyVendor),
                    value: String(companyVendor.id)
                  }
                }
              />
            </div>
            {companyVendor !== null && companyVendor.vendorCompany !== null && (
              <p className={Styles.Description}>
                ※依頼先の施工会社にメールが送信されます。
              </p>
            )}
          </div>
        </div>
        {companyVendor !== null && companyVendor.cautionaryNoteHtml !== '' && (
          <div className={Styles.FormRow}>
            <div className={Styles.FormLabel}>
              <span className={Styles.FormLabelText}>注意事項</span>
            </div>
            <div className={Styles.FormContent}>
              <AccordionHtml
                html={companyVendor.cautionaryNoteHtml}
                maxHeightForClosed={150}
              />
            </div>
          </div>
        )}
        {companyVendor !== null &&
          companyVendor.vendorCompany?.id !== VENDOR_COMPANY_ITANDI_ID && (
            <>
              <div className={Styles.FormRow}>
                <div className={Styles.FormLabel}>
                  <span className={Styles.FormLabelText}>入居者の情報</span>
                  <span className={Styles.RequiredLabel}>
                    <ItandiChip label="任意" />
                  </span>
                </div>
                <div className={Styles.FormContent}>
                  <InputCheckBox
                    checked={watch('showTenant')}
                    labelText="施工会社に入居者の情報を表示するか"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      onChangeCheckBox(e, 'showTenant');
                    }}
                  />
                </div>
              </div>
              <div className={Styles.FormRow}>
                <div className={Styles.FormLabel}>
                  <span className={Styles.FormLabelText}>見積のスキップ</span>
                  <span className={Styles.RequiredLabel}>
                    <ItandiChip label="任意" />
                  </span>
                </div>
                <div className={Styles.FormContent}>
                  <InputCheckBox
                    checked={watch('skipEstimation')}
                    disabled={companyVendor?.vendorEstimationRequired}
                    labelText="施工会社の見積をスキップする"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      onChangeCheckBox(e, 'skipEstimation');
                    }}
                  />
                  {companyVendor.vendorEstimationRequired && (
                    <Tooltip>
                      この施工会社はシステム上見積入力が必須に設定されています
                    </Tooltip>
                  )}
                </div>
              </div>
            </>
          )}
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>発注書請書の宛名</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <Input
                isInvalid={errors.estimateAddressName != null}
                placeholder="例）サンプル株式会社"
                {...register('estimateAddressName')}
              />
            </div>
            {errors.estimateAddressName != null && (
              <div className={Styles.ErrorMessage}>
                {errors.estimateAddressName.message}
              </div>
            )}
            <p className={Styles.Description}>
              発注者が貸主（オーナー）様になる場合は、貸主名を入力してください
              <br />
              内装工事くんおまかせプランの場合には、発注者は原則管理会社となります。
            </p>
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>その他ご要望、特筆事項</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <InputText
                isInvalid={errors.note != null}
                rows={5}
                {...register('note')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
